import { 
    apiDetailsObj, 
    searchResultsObj, 
    sharedVariables, 
    excelSearchResponseReceived, 
    initialProductRefsList,
    noDocSelected,
    allDocsSelected,
    filterObj,
    accessTokenObj
    // getAllLanguagesPreSelectedFlag,
} from '../shared-data.js';
import{trackingViewPageEvent} from './analytics-service'

let storeAPIDetailsObj;
apiDetailsObj.subscribe((obj) => {
    if (obj.localEndpoint !== "") {
        storeAPIDetailsObj = obj;
        //getPageViewGAProp();
    }
})

let accessTokenValue;
accessTokenObj.subscribe((accesstoken) => {
    accessTokenValue = accesstoken;
})

let filterValue;
filterObj.subscribe((filter) => {
    filterValue = filter;
})


export async function getFallBackCountryAndLanguage(globalEndpoint,ccLc, appSource) {
    return await fetch(
        globalEndpoint + '/getPrimaryCountryAndLanguage/?ccLc=' + ccLc + "&appSource=" + appSource,
    ).then(
        r => r.text()
    )   
 }

 export async function getRTLCountry(globalEndpoint,ccLc, appSource) {
    return await fetch(
        globalEndpoint + '/getRTLCountry/?ccLc=' + ccLc + "&appSource=" + appSource,
        { responseType: 'text' }
    ).then(
        r => r.text()
    )
}
export async function getPageViewGAProp() {
    return await fetch(
        setEndpoint() + '/getPageViewGAProperties/?ccLc=' + getCcLc() + "&appSource=" + storeAPIDetailsObj.appSource,
        { responseType: 'text' }
    ).then(
        r => r.json()
    ).then(data => {
            trackingViewPageEvent(data.articleTitle, data.businessUnit, data.digitalPlatform, data.digitalPlatformCountry, data.environment, data.fromWebView, data.pageCategory, data.pageCategoryId, data.pageLanguage, data.pageProductId, data.pageRangeId, data.pageSubCategory, data.pageSubCategoryId, data.pageSubSubCategory, data.pageTitle, data.pageTopCategory, data.template);
        }
    )
}
export async function getGuidedProduct(searchValue){
    let response;
    searchValue = searchValue.toUpperCase();
      await fetch(
          setEndpoint() + '/getGuidedKeywords/?appSource=' + storeAPIDetailsObj.appSource +'&keyword='+ searchValue  + '&maxCount=' + storeAPIDetailsObj.searchLimit ,
          {method:'POST', body: JSON.stringify({
            accessToken: accessTokenValue !=undefined ? accessTokenValue : ""
        }),  headers:{'content-type': 'application/json'} }
         
      )
      .then(r => r.json()
      )
      .then(data => {
          response=data;
         
      })
     return(response)
}
export async function getExlParse(event) {
    initialProductRefsList.set([]);
    let formData = new FormData();
    let Response;
    formData.append("file", event.path[0].files[0]);
    await fetch(
        setEndpoint() + "/getExlParse/",
        { method: "POST", body: formData }
    ) .then(r => r.json())
    .then(data => {
        Response=data;
    
    })
   return(Response);
        
}



export async function excelSearch(event) {
    updateSharedVariablesObj("showLoadingIndicator", true);
    initialProductRefsList.set([]);
    let formData = new FormData();
    formData.append("file", event.path[0].files[0]);
    await fetch(
        setEndpoint() + "/getProductDetails/?appSource=" + storeAPIDetailsObj.appSource + "&productReference=",
        { method: "POST", body: formData }
    )
        .then(r => r.json())
        .then(data => {
            if (data.documentsCount != 0) {
                updateSharedVariablesObj("noResultsFound", false);
                excelSearchResponseReceived.set(Math.random());
                data.documents.map((document) => {
                    document.showShortDescription = true;
                })
            } else {
                updateSharedVariablesObj("noResultsFound", true);
                excelSearchResponseReceived.set(Math.random());
            }
            setPageWithNewData(data)
        });
}

export async function getExcel(excelJsonData) {
    updateSharedVariablesObj("showLoadingIndicator", true);
    await fetch(
        setEndpoint() + "downloadExcel/?appSource=public-en",
        { method: "POST", body: JSON.stringify(excelJsonData),  headers:{'content-type': 'application/json'} }
    )
    .then(r => r.blob())
    .then(data => {
        let file = new Blob([data], {
            type: 'application/vnd.ms-excel'
        });
        let fileURL = URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'ProductAndImageDetails.xlsx';
        document.body.appendChild(a); 
        a.click(); 
        document.body.removeChild(a); 
        updateSharedVariablesObj("showLoadingIndicator", false);
    });
}
function setEndpoint(){
    if(storeAPIDetailsObj.fallbackEndpoint === undefined){
        return storeAPIDetailsObj.localEndpoint;
    }
    return storeAPIDetailsObj.fallbackEndpoint;
    
}

function getCcLc(){
    return storeAPIDetailsObj.countryCode + "_" + storeAPIDetailsObj.languageCode;
}

function resetSelectButttonState(){
    noDocSelected.set(true);
    allDocsSelected.set(false);
}

function setPageWithNewData(data){
    updateSharedVariablesObj("showLoadingIndicator", false);
    searchResultsObj.set(data);
    resetSelectButttonState();
}

function updateSharedVariablesObj(key, value) {
    sharedVariables.update((obj) => {
        obj[key] = value;
        return obj;
    });
}
