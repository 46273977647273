
import { getFallBackCountryAndLanguage, getRTLCountry } from "./data-service.js"
import { apiDetailsObj, isLandingPage, searchValue, isRTLCountry, filterObj, accessTokenObj, toggleclickObj, 
  ddcSearchInstruction, ddcSearchPlaceholder, disableDDCSearchInstruction, disableDDCTitle, embeddedsurveybtnObj
} from "../shared-data.js"
import { setLabels } from "./localization-service.js";

export function handleAPIDtaInput(apiendpointdetails, toggleclick) {
  // umcomment the below to get listing page when run this component individually
  // landingpage = false
  if (apiendpointdetails) {
    let arr = apiendpointdetails.split("::");
    let cc = arr[1].toLowerCase();
    let lc = arr[2].toLowerCase();
    let obj = {};
    obj.localEndpoint = updateEndPoint(arr[0], cc, lc);
    obj.globalEndpoint = updateEndPoint(arr[0], "ww", "en");
    if(cc =='cn' && lc == 'zh') {
      obj.globalEndpoint = updateEndPoint(arr[0], "cn", "zh");
    }
    obj.baseEndpoint = createBaseEndpoint(arr[0]);
    obj.countryCode = cc;
    obj.languageCode = lc;
    obj.appSource = arr[3];
    obj.searchLimit = arr[4];
    obj.toggleclick = arr[6];
    getFallBackCountryAndLanguage(obj.globalEndpoint, cc + "_" + lc, arr[3]).then(data => {
      if (data && typeof (data) === "string") {
        let cclcArr = data.split("/");
        obj.fallbackEndpoint = updateEndPoint(arr[0], cclcArr[0], cclcArr[1]);
        obj.countryCode = cclcArr[0];
        obj.languageCode = cclcArr[1];
      }
      apiDetailsObj.set(obj);
    });
    getRTLCountry(obj.globalEndpoint, cc + "_" + lc, arr[3]).then(data => {
      isRTLCountry.set(data);
    });
  }
}

export function handleInputDetails(searchvalue, landingpage, filter, accesstoken, toggleclick, search_instruction, search_placeholder, disable_search_instruction, disable_page_title, embeddedsurveybtn) {
  isLandingPage.set(landingpage?JSON.parse(landingpage):false);
  searchValue.set(searchvalue);
  filterObj.set(filter);
  accessTokenObj.set(accesstoken);
  toggleclickObj.set(toggleclick === "true" ? true : false);
  ddcSearchInstruction.set(search_instruction);
  ddcSearchPlaceholder.set(search_placeholder);
  disableDDCSearchInstruction.set(disable_search_instruction);
  disableDDCTitle.set(disable_page_title == 'true' ? true : false);
  embeddedsurveybtnObj.set(embeddedsurveybtn === "true" ? true : false);
}

function updateEndPoint(endpopintTemplate, countryCode, languageCode) {
  return endpopintTemplate.replace(/{cc}/, countryCode)
    .replace(/{lc}/, languageCode)
}

function createBaseEndpoint(inputEndpointTemplate) {
  let baseUrl = inputEndpointTemplate.substring(0, inputEndpointTemplate.indexOf('{') - 1).replace('download-api', '');
  return baseUrl;
}