import { writable, readable } from 'svelte/store';

export const labels = writable({
    "All": '',
    "default.sort.by.field": '',
    "prm-add-to-my-documents": '',
    "prm-add-to-my-documents-tooltip": '',
    "prm-addthis-email": '',
    "prm-advanced-badge": '',
    "prm-advanced-search-title": '',
    "prm-all-files": '',
    "prm-all-our-products-a-z": '',
    "prm-approval-office": '',
    "prm-atoz-browse-all": '',
    "prm-atoz-list-close": '',
    "prm-atoz-or-browse": '',
    "prm-breadcrumb-documentsAndDownloads": '',
    "prm-breadcrumb-support": '',
    "prm-browse-online": '',
    "prm-cart-download-all-files": '',
    "prm-category": '',
    "prm-conformity-info": '',
    "prm-connection-timed-out-message": '',
    "prm-copy-checksum": '',
    "prm-copy-checksum-tooltip": '',
    "prm-country": '',
    "prm-date-new": '',
    "prm-date-old": '',
    "prm-ddc-banner-title": '',
    "prm-ddc-read-less": '',
    "prm-ddc-read-more": '',
    "prm-delete-from-my-documents": '',
    "prm-delete-from-my-documents-tooltip": '',
    "prm-doc-detail-statement-of-version": '',
    "prm-document--all-files-download": '',
    "prm-document--copy-url": '',
    "prm-document-category": '',
    "prm-document-date": '',
    "prm-document-description": '',
    "prm-document-download-all-files": '',
    "prm-document-download-center": '',
    "prm-document-filter": '',
    "prm-document-filter-language": '',
    "prm-document-filter-range-current-product": '',
    "prm-document-list": '',
    "prm-document-not-available": '',
    "prm-document-range": '',
    "prm-document-search-label-text": '',
    "prm-document-search-label-text-for": '',
    "prm-document-search-label-text-of": '',
    "prm-document-search-label-text-result": '',
    "prm-document-search-label-text-results": '',
    "prm-document-search-label-text-results-found": '',
    "prm-document-sub-category": '',
    "prm-document-version": '',
    "prm-document-versions": '',
    "prm-documents": '',
    "prm-download": '',
    "prm-download-center": '',
    "prm-download-documents-support": '',
    "prm-download-multiple-files": '',
    "prm-error-input-empty-landingPage": '',
    "prm-error-message-and-page-refresh-suggestions": '',
    "prm-expiration-date": '',
    "prm-file-name": '',
    "prm-file-type-zip": '',
    "prm-files": '',
    "prm-filter-by": '',
    "prm-highligths-title": '',
    "prm-language-filter-all": '',
    "prm-language-label-**": '',
    "prm-language-label-**_": '',
    "prm-language-label-**_null": '',
    "prm-language-label-ar_SA": '',
    "prm-language-label-bg_BG": '',
    "prm-language-label-ch_CN": '',
    "prm-language-label-cs_CZ": '',
    "prm-language-label-da_DK": '',
    "prm-language-label-de_AT": '',
    "prm-language-label-de_DE": '',
    "prm-language-label-el_GR": '',
    "prm-language-label-en_AU": '',
    "prm-language-label-en_CA": '',
    "prm-language-label-en_GB": '',
    "prm-language-label-en_US": '',
    "prm-language-label-es_AR": '',
    "prm-language-label-es_CL": '',
    "prm-language-label-es_CO": '',
    "prm-language-label-es_CR": '',
    "prm-language-label-es_EC": '',
    "prm-language-label-es_ES": '',
    "prm-language-label-es_MX": '',
    "prm-language-label-es_PE": '',
    "prm-language-label-es_VE": '',
    "prm-language-label-et_EE": '',
    "prm-language-label-fi_FI": '',
    "prm-language-label-fr_CA": '',
    "prm-language-label-fr_CH": '',
    "prm-language-label-fr_FR": '',
    "prm-language-label-hr_HR": '',
    "prm-language-label-hu_HU": '',
    "prm-language-label-it_IT": '',
    "prm-language-label-ja_JP": '',
    "prm-language-label-kk_KZ": '',
    "prm-language-label-ko_KR": '',
    "prm-language-label-lt_LT": '',
    "prm-language-label-lv_LV": '',
    "prm-language-label-nl_BE": '',
    "prm-language-label-nl_NL": '',
    "prm-language-label-no_NO": '',
    "prm-language-label-pl_PL": '',
    "prm-language-label-pt_BR": '',
    "prm-language-label-pt_PT": '',
    "prm-language-label-ro_RO": '',
    "prm-language-label-ru_KZ": '',
    "prm-language-label-ru_RU": '',
    "prm-language-label-sh_RS": '',
    "prm-language-label-sk_SK": '',
    "prm-language-label-sl_SI": '',
    "prm-language-label-sr_RS": '',
    "prm-language-label-sv_SE": '',
    "prm-language-label-tr_TR": '',
    "prm-language-label-uk_UA": '',
    "prm-language-label-zh_CN": '',
    "prm-language-label-zh_HK": '',
    "prm-language-label-zh_TW": '',
    "prm-latest": '',
    "prm-load-more-docs": '',
    "prm-looking-for-a-document": '',
    "prm-mass-dw-link-title-my-schneider": '',
    "prm-mass-dw-link-tool-tip-my-schneider": '',
    "prm-mass-dw-subtitle": '',
    "prm-mass-dw-title": '',
    "prm-mdw-doc-type-image-label": '',
    "prm-mdw-export-excel-instruction1": '',
    "prm-mdw-export-instruction1": '',
    "prm-mdw-export-instruction2": '',
    "prm-mdw-title": '',
    "prm-mdw-unselect-all": '',
    "prm-meta-documents-and-downloads": '',
    "prm-meta-documents-downloads-schneider-electric": '',
    "prm-my-list-disable-popup-blocker": '',
    "prm-my-list-find-more-documents": '',
    "prm-my-list-remove-all-documents": '',
    "prm-mylist-added-on": '',
    "prm-mylist-cart-full": '',
    "prm-mylist-find-a-dealer": '',
    "prm-mylist-in-your-list": '',
    "prm-mylist-items-of": '',
    "prm-mylist-store-email-share": '',
    "prm-mylist-view-bookmarked-products": '',
    "prm-mylist-view-my-documents": '',
    "prm-mylist-you-have": '',
    "prm-not-available": '',
    "prm-obsolete-document-disclaimer": '',
    "prm-other-languages": '',
    "prm-other-versions": '',
    "prm-pagination-page": '',
    "prm-perimeter": '',
    "prm-popular-document-types": '',
    "prm-product-ranges": '',
    "prm-range": '',
    "prm-results-for": '',
    "prm-results-list-date-format": '',
    "prm-search-button": '',
    "prm-search-by-keyword-or-product": '',
    "prm-search-details": '',
    "prm-search-filter-label-application": '',
    "prm-search-filters": '',
    "prm-search-format": '',
    "prm-search-grid-view": '',
    "prm-search-in-download-center": '',
    "prm-search-keyword-text-large": '',
    "prm-search-keyword-text-small": '',
    "prm-search-label-category": '',
    "prm-search-label-doc-languages": '',
    "prm-search-label-doc-type": '',
    "prm-search-label-doc-type-group": '',
    "prm-search-label-doc-types": '',
    "prm-search-label-print": '',
    "prm-search-label-show": '',
    "prm-search-label-sub-category": '',
    "prm-search-list-view": '',
    "prm-search-no-documents-found": '',
    "prm-search-operating-system": '',
    "prm-search-product-fromlist-link": '',
    "prm-search-reset": '',
    "prm-search-reset-all": '',
    "prm-search-results-in-all-the-site": '',
    "prm-search-share": '',
    "prm-search-view-documents": '',
    "prm-select-document-type": '',
    "prm-show-previous-versions": '',
    "prm-sort-by-popularity": '',
    "prm-standard": '',
    "prm-sub-category": '',
    "prm-sub-type": '',
    "prm-subtitle": '',
    "prm-table-data-delete": '',
    "prm-table-data-pertinence": '',
    "prm-table-data-reference": '',
    "prm-table-data-references": '',
    "prm-table-data-size": '',
    "prm-table-data-title": '',
    "prm-table-date-doc-date": '',
    "prm-table-date-doc-dates": '',
    "prm-table-title-sortby": '',
    "prm-title-document-all-clear": '',
    "prm-title-document-collapse": '',
    "prm-title-document-expand": '',
    "prm-title-document-filter": '',
    "prm-title-document-other": '',
    "prm-title-downloads": '',
    "prm-title-for": '',
    "prm-title-search-current-productPage": '',
    "prm-title-search-legacy-productPage": '',
    "prm-title-search-product-range": '',
    "prm-title-search-range-productPage": '',
    "prm-user-type-premium": '',
    "prm-view": '',
    "prm-view-less": '',
    "prm-view-more": '',
    "prm-view-more-details": '',
    "prm-view-online": '',
    "prm.ddc.operating.system": '',
    "prm.document.added.successfully": '',
    "prm.document.removed.successfully": '',
    "prm.mdw.brand": '',
    "prm.mdw.comm.ref": '',
    "prm.mdw.download.template": '',
    "prm.mdw.file.requirements": '',
    "prm.mdw.file.type.validation.message": '',
    "prm.mdw.import.csv.or.xls.file": '',
    "prm.mdw.multiple.comm.ref.separation.message": '',
    "prm.mdw.populate.one.ref.per.row": '',
    "prm.mdw.prod.ref": '',
    "prm.mdw.prod.ref.spell.check.messgae": '',
    "prm.mdw.search.by.comm.ref": '',
    "prm.mdw.select": '',
    "prm.mdw.select.all": '',
    "prm.mdw.suggestion": '',
    "prm.mdw.unselect": '',
    "prm.mdw.view": '',
    "prm.mdw.zero.search.results.message": '',
    "prm.part.number": '',
    "prm.refine.filters": '',
    "prm.search.by.part.number": '',
    "prm.suggestion.look.at.our": '',
    "prm.suggestion.products.a.to.z.page": '',
    "prm.suggestion.spell.check": '',
    "prm.suggestion.suggestions": '',
    "prm.suggestion.to.browse.by.product.category": '',
    "prm.suggestion.try.general.keywords": '',
    "prm.suggestion.zero.results.message": ''
});


export const labelKeys = readable({
    "All": "All",
"default.sort.by.field": "default.sort.by.field",
"prm-add-to-my-documents": "prm-add-to-my-documents",
"prm-add-to-my-documents-tooltip": "prm-add-to-my-documents-tooltip",
"prm-addthis-email": "prm-addthis-email",
"prm-advanced-badge": "prm-advanced-badge",
"prm-advanced-search-title": "prm-advanced-search-title",
"prm-all-files": "prm-all-files",
"prm-all-our-products-a-z": "prm-all-our-products-a-z",
"prm-approval-office": "prm-approval-office",
"prm-atoz-browse-all": "prm-atoz-browse-all",
"prm-atoz-list-close": "prm-atoz-list-close",
"prm-atoz-or-browse": "prm-atoz-or-browse",
"prm-breadcrumb-documentsAndDownloads": "prm-breadcrumb-documentsAndDownloads",
"prm-breadcrumb-support": "prm-breadcrumb-support",
"prm-browse-online": "prm-browse-online",
"prm-cart-download-all-files": "prm-cart-download-all-files",
"prm-category": "prm-category",
"prm-conformity-info": "prm-conformity-info",
"prm-connection-timed-out-message": "prm-connection-timed-out-message",
"prm-copy-checksum": "prm-copy-checksum",
"prm-copy-checksum-tooltip": "prm-copy-checksum-tooltip",
"prm-country": "prm-country",
"prm-date-new": "prm-date-new",
"prm-date-old": "prm-date-old",
"prm-ddc-banner-title": "prm-ddc-banner-title",
"prm-ddc-read-less": "prm-ddc-read-less",
"prm-ddc-read-more": "prm-ddc-read-more",
"prm-delete-from-my-documents": "prm-delete-from-my-documents",
"prm-delete-from-my-documents-tooltip": "prm-delete-from-my-documents-tooltip",
"prm-doc-detail-statement-of-version": "prm-doc-detail-statement-of-version",
"prm-document--all-files-download": "prm-document--all-files-download",
"prm-document--copy-url": "prm-document--copy-url",
"prm-document-category": "prm-document-category",
"prm-document-date": "prm-document-date",
"prm-document-description": "prm-document-description",
"prm-document-download-all-files": "prm-document-download-all-files",
"prm-document-download-center": "prm-document-download-center",
"prm-document-filter": "prm-document-filter",
"prm-document-filter-language": "prm-document-filter-language",
"prm-document-filter-range-current-product": "prm-document-filter-range-current-product",
"prm-document-list": "prm-document-list",
"prm-document-not-available": "prm-document-not-available",
"prm-document-range": "prm-document-range",
"prm-document-search-label-text": "prm-document-search-label-text",
"prm-document-search-label-text-for": "prm-document-search-label-text-for",
"prm-document-search-label-text-of": "prm-document-search-label-text-of",
"prm-document-search-label-text-result": "prm-document-search-label-text-result",
"prm-document-search-label-text-results": "prm-document-search-label-text-results",
"prm-document-search-label-text-results-found": "prm-document-search-label-text-results-found",
"prm-document-sub-category": "prm-document-sub-category",
"prm-document-version": "prm-document-version",
"prm-document-versions": "prm-document-versions",
"prm-documents": "prm-documents",
"prm-download": "prm-download",
"prm-download-center": "prm-download-center",
"prm-download-documents-support": "prm-download-documents-support",
"prm-download-multiple-files": "prm-download-multiple-files",
"prm-error-input-empty-landingPage": "prm-error-input-empty-landingPage",
"prm-error-message-and-page-refresh-suggestions": "prm-error-message-and-page-refresh-suggestions",
"prm-expiration-date": "prm-expiration-date",
"prm-file-name": "prm-file-name",
"prm-file-type-zip": "prm-file-type-zip",
"prm-files": "prm-files",
"prm-filter-by": "prm-filter-by",
"prm-highligths-title": "prm-highligths-title",
"prm-language-filter-all": "prm-language-filter-all",
"prm-language-label-**": "prm-language-label-**",
"prm-language-label-**_": "prm-language-label-**_",
"prm-language-label-**_null": "prm-language-label-**_null",
"prm-language-label-ar_SA": "prm-language-label-ar_SA",
"prm-language-label-bg_BG": "prm-language-label-bg_BG",
"prm-language-label-ch_CN": "prm-language-label-ch_CN",
"prm-language-label-cs_CZ": "prm-language-label-cs_CZ",
"prm-language-label-da_DK": "prm-language-label-da_DK",
"prm-language-label-de_AT": "prm-language-label-de_AT",
"prm-language-label-de_DE": "prm-language-label-de_DE",
"prm-language-label-el_GR": "prm-language-label-el_GR",
"prm-language-label-en_AU": "prm-language-label-en_AU",
"prm-language-label-en_CA": "prm-language-label-en_CA",
"prm-language-label-en_GB": "prm-language-label-en_GB",
"prm-language-label-en_US": "prm-language-label-en_US",
"prm-language-label-es_AR": "prm-language-label-es_AR",
"prm-language-label-es_CL": "prm-language-label-es_CL",
"prm-language-label-es_CO": "prm-language-label-es_CO",
"prm-language-label-es_CR": "prm-language-label-es_CR",
"prm-language-label-es_EC": "prm-language-label-es_EC",
"prm-language-label-es_ES": "prm-language-label-es_ES",
"prm-language-label-es_MX": "prm-language-label-es_MX",
"prm-language-label-es_PE": "prm-language-label-es_PE",
"prm-language-label-es_VE": "prm-language-label-es_VE",
"prm-language-label-et_EE": "prm-language-label-et_EE",
"prm-language-label-fi_FI": "prm-language-label-fi_FI",
"prm-language-label-fr_CA": "prm-language-label-fr_CA",
"prm-language-label-fr_CH": "prm-language-label-fr_CH",
"prm-language-label-fr_FR": "prm-language-label-fr_FR",
"prm-language-label-hr_HR": "prm-language-label-hr_HR",
"prm-language-label-hu_HU": "prm-language-label-hu_HU",
"prm-language-label-it_IT": "prm-language-label-it_IT",
"prm-language-label-ja_JP": "prm-language-label-ja_JP",
"prm-language-label-kk_KZ": "prm-language-label-kk_KZ",
"prm-language-label-ko_KR": "prm-language-label-ko_KR",
"prm-language-label-lt_LT": "prm-language-label-lt_LT",
"prm-language-label-lv_LV": "prm-language-label-lv_LV",
"prm-language-label-nl_BE": "prm-language-label-nl_BE",
"prm-language-label-nl_NL": "prm-language-label-nl_NL",
"prm-language-label-no_NO": "prm-language-label-no_NO",
"prm-language-label-pl_PL": "prm-language-label-pl_PL",
"prm-language-label-pt_BR": "prm-language-label-pt_BR",
"prm-language-label-pt_PT": "prm-language-label-pt_PT",
"prm-language-label-ro_RO": "prm-language-label-ro_RO",
"prm-language-label-ru_KZ": "prm-language-label-ru_KZ",
"prm-language-label-ru_RU": "prm-language-label-ru_RU",
"prm-language-label-sh_RS": "prm-language-label-sh_RS",
"prm-language-label-sk_SK": "prm-language-label-sk_SK",
"prm-language-label-sl_SI": "prm-language-label-sl_SI",
"prm-language-label-sr_RS": "prm-language-label-sr_RS",
"prm-language-label-sv_SE": "prm-language-label-sv_SE",
"prm-language-label-tr_TR": "prm-language-label-tr_TR",
"prm-language-label-uk_UA": "prm-language-label-uk_UA",
"prm-language-label-zh_CN": "prm-language-label-zh_CN",
"prm-language-label-zh_HK": "prm-language-label-zh_HK",
"prm-language-label-zh_TW": "prm-language-label-zh_TW",
"prm-latest": "prm-latest",
"prm-load-more-docs": "prm-load-more-docs",
"prm-looking-for-a-document": "prm-looking-for-a-document",
"prm-mass-dw-link-title-my-schneider": "prm-mass-dw-link-title-my-schneider",
"prm-mass-dw-link-tool-tip-my-schneider": "prm-mass-dw-link-tool-tip-my-schneider",
"prm-mass-dw-subtitle": "prm-mass-dw-subtitle",
"prm-mass-dw-title": "prm-mass-dw-title",
"prm-mdw-doc-type-image-label": "prm-mdw-doc-type-image-label",
"prm-mdw-export-excel-instruction1": "prm-mdw-export-excel-instruction1",
"prm-mdw-export-instruction1": "prm-mdw-export-instruction1",
"prm-mdw-export-instruction2": "prm-mdw-export-instruction2",
"prm-mdw-title": "prm-mdw-title",
"prm-mdw-unselect-all": "prm-mdw-unselect-all",
"prm-meta-documents-and-downloads": "prm-meta-documents-and-downloads",
"prm-meta-documents-downloads-schneider-electric": "prm-meta-documents-downloads-schneider-electric",
"prm-my-list-disable-popup-blocker": "prm-my-list-disable-popup-blocker",
"prm-my-list-find-more-documents": "prm-my-list-find-more-documents",
"prm-my-list-remove-all-documents": "prm-my-list-remove-all-documents",
"prm-mylist-added-on": "prm-mylist-added-on",
"prm-mylist-cart-full": "prm-mylist-cart-full",
"prm-mylist-find-a-dealer": "prm-mylist-find-a-dealer",
"prm-mylist-in-your-list": "prm-mylist-in-your-list",
"prm-mylist-items-of": "prm-mylist-items-of",
"prm-mylist-store-email-share": "prm-mylist-store-email-share",
"prm-mylist-view-bookmarked-products": "prm-mylist-view-bookmarked-products",
"prm-mylist-view-my-documents": "prm-mylist-view-my-documents",
"prm-mylist-you-have": "prm-mylist-you-have",
"prm-not-available": "prm-not-available",
"prm-obsolete-document-disclaimer": "prm-obsolete-document-disclaimer",
"prm-other-languages": "prm-other-languages",
"prm-other-versions": "prm-other-versions",
"prm-pagination-page": "prm-pagination-page",
"prm-perimeter": "prm-perimeter",
"prm-popular-document-types": "prm-popular-document-types",
"prm-product-ranges": "prm-product-ranges",
"prm-range": "prm-range",
"prm-results-for": "prm-results-for",
"prm-results-list-date-format": "prm-results-list-date-format",
"prm-search-button": "prm-search-button",
"prm-search-by-keyword-or-product": "prm-search-by-keyword-or-product",
"prm-search-details": "prm-search-details",
"prm-search-filter-label-application": "prm-search-filter-label-application",
"prm-search-filters": "prm-search-filters",
"prm-search-format": "prm-search-format",
"prm-search-grid-view": "prm-search-grid-view",
"prm-search-in-download-center": "prm-search-in-download-center",
"prm-search-keyword-text-large": "prm-search-keyword-text-large",
"prm-search-keyword-text-small": "prm-search-keyword-text-small",
"prm-search-label-category": "prm-search-label-category",
"prm-search-label-doc-languages": "prm-search-label-doc-languages",
"prm-search-label-doc-type": "prm-search-label-doc-type",
"prm-search-label-doc-type-group": "prm-search-label-doc-type-group",
"prm-search-label-doc-types": "prm-search-label-doc-types",
"prm-search-label-print": "prm-search-label-print",
"prm-search-label-show": "prm-search-label-show",
"prm-search-label-sub-category": "prm-search-label-sub-category",
"prm-search-list-view": "prm-search-list-view",
"prm-search-no-documents-found": "prm-search-no-documents-found",
"prm-search-operating-system": "prm-search-operating-system",
"prm-search-product-fromlist-link": "prm-search-product-fromlist-link",
"prm-search-reset": "prm-search-reset",
"prm-search-reset-all": "prm-search-reset-all",
"prm-search-results-in-all-the-site": "prm-search-results-in-all-the-site",
"prm-search-share": "prm-search-share",
"prm-search-view-documents": "prm-search-view-documents",
"prm-select-document-type": "prm-select-document-type",
"prm-show-previous-versions": "prm-show-previous-versions",
"prm-sort-by-popularity": "prm-sort-by-popularity",
"prm-standard": "prm-standard",
"prm-sub-category": "prm-sub-category",
"prm-sub-type": "prm-sub-type",
"prm-subtitle": "prm-subtitle",
"prm-table-data-delete": "prm-table-data-delete",
"prm-table-data-pertinence": "prm-table-data-pertinence",
"prm-table-data-reference": "prm-table-data-reference",
"prm-table-data-references": "prm-table-data-references",
"prm-table-data-size": "prm-table-data-size",
"prm-table-data-title": "prm-table-data-title",
"prm-table-date-doc-date": "prm-table-date-doc-date",
"prm-table-date-doc-dates": "prm-table-date-doc-dates",
"prm-table-title-sortby": "prm-table-title-sortby",
"prm-title-document-all-clear": "prm-title-document-all-clear",
"prm-title-document-collapse": "prm-title-document-collapse",
"prm-title-document-expand": "prm-title-document-expand",
"prm-title-document-filter": "prm-title-document-filter",
"prm-title-document-other": "prm-title-document-other",
"prm-title-downloads": "prm-title-downloads",
"prm-title-for": "prm-title-for",
"prm-title-search-current-productPage": "prm-title-search-current-productPage",
"prm-title-search-legacy-productPage": "prm-title-search-legacy-productPage",
"prm-title-search-product-range": "prm-title-search-product-range",
"prm-title-search-range-productPage": "prm-title-search-range-productPage",
"prm-user-type-premium": "prm-user-type-premium",
"prm-view": "prm-view",
"prm-view-less": "prm-view-less",
"prm-view-more": "prm-view-more",
"prm-view-more-details": "prm-view-more-details",
"prm-view-online": "prm-view-online",
"prm.ddc.operating.system": "prm.ddc.operating.system",
"prm.document.added.successfully": "prm.document.added.successfully",
"prm.document.removed.successfully": "prm.document.removed.successfully",
"prm.mdw.brand": "prm.mdw.brand",
"prm.mdw.comm.ref": "prm.mdw.comm.ref",
"prm.mdw.download.template": "prm.mdw.download.template",
"prm.mdw.file.requirements": "prm.mdw.file.requirements",
"prm.mdw.file.type.validation.message": "prm.mdw.file.type.validation.message",
"prm.mdw.import.csv.or.xls.file": "prm.mdw.import.csv.or.xls.file",
"prm.mdw.multiple.comm.ref.separation.message": "prm.mdw.multiple.comm.ref.separation.message",
"prm.mdw.populate.one.ref.per.row": "prm.mdw.populate.one.ref.per.row",
"prm.mdw.prod.ref": "prm.mdw.prod.ref",
"prm.mdw.prod.ref.spell.check.messgae": "prm.mdw.prod.ref.spell.check.messgae",
"prm.mdw.search.by.comm.ref": "prm.mdw.search.by.comm.ref",
"prm.mdw.select": "prm.mdw.select",
"prm.mdw.select.all": "prm.mdw.select.all",
"prm.mdw.suggestion": "prm.mdw.suggestion",
"prm.mdw.unselect": "prm.mdw.unselect",
"prm.mdw.view": "prm.mdw.view",
"prm.mdw.zero.search.results.message": "prm.mdw.zero.search.results.message",
"prm.part.number": "prm.part.number",
"prm.refine.filters": "prm.refine.filters",
"prm.search.by.part.number": "prm.search.by.part.number",
"prm.suggestion.look.at.our": "prm.suggestion.look.at.our",
"prm.suggestion.products.a.to.z.page": "prm.suggestion.products.a.to.z.page",
"prm.suggestion.spell.check": "prm.suggestion.spell.check",
"prm.suggestion.suggestions": "prm.suggestion.suggestions",
"prm.suggestion.to.browse.by.product.category": "prm.suggestion.to.browse.by.product.category",
"prm.suggestion.try.general.keywords": "prm.suggestion.try.general.keywords",
"prm.suggestion.zero.results.message": "prm.suggestion.zero.results.message",
});

export const apiDetailsObj = writable({
    localEndpoint: "",
    globalEndpoint: "",
    baseEndpoint: "",
    fallbackEndpoint: "",
    countryCode: "",
    languageCode: "",
    appSource: "",
    searchLimit: "",
    toggleclick: "",
    embeddedsurveybtn:""
});

export const searchResultsObj = writable({});

export const sharedVariables = writable({
    showLoadingIndicator: true,
    noResultsFound: false,
    isTncAccepted: false,
    isTncAccepted: true,
    labelsLoaded: false
});



export const isRTLCountry = writable();
export const searchValue = writable("");

export const noDocSelected = writable(true);
export const selectAllClicked = writable(0);
export const unSelectAllClicked = writable(0);
export const allDocsSelected = writable(false);
export const documentsCentralState = writable([]);

export const initialProductRefsList = writable([]);
export const closeDialog = writable(false);
export const closeTnc = writable(false);
export const toggleclickObj = writable(false);
export const filterObj = writable(false);
export const accessTokenObj = writable(false);
export const isLandingPage = writable(false);
export const excelSearchResponseReceived = writable(0);
export const ddcSearchInstruction = writable('');
export const ddcSearchPlaceholder = writable('');
export const disableDDCSearchInstruction = writable('');
export const disableDDCTitle = writable(false);
export const embeddedsurveybtnObj = writable(false);


let mdAllLanguagesPreSelected = false;
export function setAllLanguagesPreSelectedFlag(state) {
    mdAllLanguagesPreSelected = state;
}
export function getAllLanguagesPreSelectedFlag() {
    return mdAllLanguagesPreSelected;
}