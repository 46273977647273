import { writable } from 'svelte/store';
import { labels, labelKeys, apiDetailsObj, sharedVariables } from '../shared-data.js';

let  allLabels, storeLabels, storeLabelKeys, storeAPIDetailsObj, localSharedVars;

apiDetailsObj.subscribe((obj) => {
    if (obj.localEndpoint !== "") {
        storeAPIDetailsObj = obj;
        if(obj.fallbackEndpoint === undefined){
            getLocalLabels();
        }
        else{
            setLabels();
        }
    }
})



export async function setLabels() {
    await fetch(
        storeAPIDetailsObj.globalEndpoint + '/getLocalizedLabels/?appSource=' + storeAPIDetailsObj.appSource
    )
        .then(r => r.json())
        .then(data => {
            allLabels = data;
            setLabelsinStore();
        },error=>{
            allLabels = {};
            setLabelsinStore();
        });
}

async function getLocalLabels() {
    await fetch(
        storeAPIDetailsObj.localEndpoint + '/getLocalizedLabels/?appSource=' + storeAPIDetailsObj.appSource
        //"mock-data/global-labels.json"
    )
        .then(r => r.json())
        .then(data => {
            allLabels = data;
            setLabelsinStore();
        },error=>{
            allLabels = {};
            setLabelsinStore();
        });
}

function setLabelsinStore() {
    labels.subscribe(
        value => {
            storeLabels = value
        }
    );
    labelKeys.subscribe(
        value => {
            storeLabelKeys = value;
        }
    )
    
    for (let label in storeLabels) {
        let labelkey = storeLabelKeys[label];
        /*if (localLabels[labelkey] !== undefined) {
            storeLabels[label] = localLabels[labelkey]
        } else if (globalLabels[labelkey] !== undefined) {
            storeLabels[label] = globalLabels[labelkey];
        }*/
        storeLabels[label] = allLabels[labelkey];
    }
    labels.set(storeLabels);
    sharedVariables.update((obj) => {
        obj.labelsLoaded = true;
        return obj;
    })
}


