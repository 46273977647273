export function SearchDocuments(searchtext) {
    window.dispatchEvent(new CustomEvent("keyword", {
        detail: searchtext,
        bubbles: true
    }));
}


export function DocRefClick(doc) {
    window.dispatchEvent(new CustomEvent("onDocReferenceClick", {
        detail: doc,
        bubbles: true
    }));
   
}
export function trackingGuidedSearch(kw55) {
    
    let trackingObject = {};
    trackingObject.event = "search";
    trackingObject.cat55 = "Download Center";
    //console.log("cat55"+cat55);
    trackingObject.guidedSearch = true;
    trackingObject.kw55 = kw55;
    trackingObject.refinedSearch = false;
    trackingObject.siteSearchResults = 1;
    dispatchEvent(trackingObject);
  }

export function trackingViewPageEvent(articleTitle, businessUnit, digitalPlatform, digitalPlatformCountry, environment, fromWebView, pageCategory, pageCategoryId, pageLanguage, pageProductId, pageRangeId, pageSubCategory, pageSubCategoryId, pageSubSubCategory, pageTitle, pageTopCategory, template) {
    let trackingObject = {};
    trackingObject.event = "pageView";
    trackingObject.articleTitle = articleTitle;
    trackingObject.businessUnit = businessUnit;
    trackingObject.digitalPlatform = digitalPlatform;
    trackingObject.digitalPlatformCountry = digitalPlatformCountry;
    trackingObject.environment = environment;
    trackingObject.fromWebView = fromWebView == "True" ? true : false;
    trackingObject.pageCategory = pageCategory;
    trackingObject.pageCategoryId = pageCategoryId;
    trackingObject.pageLanguage = pageLanguage;
    trackingObject.pageProductId = pageProductId;
    trackingObject.pageRangeId = pageRangeId;
    trackingObject.pageSubCategory = pageSubCategory;
    trackingObject.pageSubCategoryId = pageSubCategoryId;
    trackingObject.pageSubSubCategory = pageSubSubCategory;
    trackingObject.pageTitle = pageTitle;
    trackingObject.pageTopCategory = pageTopCategory;
    trackingObject.template = template;

    window.dispatchEvent(new CustomEvent("pageViewEvent", {
        detail: trackingObject,
        bubbles: true
    }));
}

function dispatchEvent(trackingObject) {
    //console.log(trackingObject)
    window.dispatchEvent(new CustomEvent("track_analytics", {
      detail: trackingObject,
      bubbles: true
    }));
   
  }